<script setup>
import SignPad from 'sja.vue.libraries.components.SignPad.vue'

const props = defineProps({
    sjaID: Number,
    personRow : Object,
    dataObject: {
        type: Object,
        required: false
    }
});

</script>

<template>
    <OModal name="signatureModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('Sign SJA') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body d-flex justify-content-center">
                    <SignPad :sjaID="props.sjaID" :personRow="props.personRow" :dataObject="props.dataObject"></SignPad>
                </div>
            </div>
        </div>
    </OModal>
</template>

